// 辅助函数，通过当前path递归计算上级菜单
// 用于面包屑和边栏菜单展示
export const getPathQueue = (menus: any[], pathname: string | any[]) => {
  // 递归函数
  const hasChild = (menu: { children: any; path: any; insert: any }, paths: any[]) => {
    const { children, path, insert = false } = menu;
    if ((path === pathname || (pathname.indexOf(path) === 0 && !children)) && !insert) {
      return paths.push(menu);
    }
    if (!children) return false;
    const has = children.some((c: any) => hasChild(c, paths));
    if (has) {
      return paths.push(menu);
    }
  };

  const paths: any[] = [];
  menus.forEach((i: any) => hasChild(i, paths));
  return paths.reverse();
};
