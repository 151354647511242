import { Provider, useSelector } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import { init } from '@rematch/core';
import zhCN from 'antd/locale/zh_CN';
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

import Layouts from 'layouts/index';
import { ThemeMap } from 'constants/common';

import * as models from './models';

const store = init({ models });
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.locale('zh-cn');

interface RootState {
  localTheme: string;
}

const ConfigComp = () => {
  const localTheme = useSelector((state: { layouts: RootState }) => state.layouts.localTheme);

  const themeComponents = {
    Layout: {
      colorBgContainer: localTheme === ThemeMap.Protect ? '#deebdf' : '#fff',
      lightSiderBg: '#fff',
      headerBg: '#fff',
    },
    Table: {
      colorBgSolidActive: localTheme === ThemeMap.Protect ? '#C7EDCC' : '#fff',
      rowSelectedBg: localTheme === ThemeMap.Protect ? '#C7EDCC' : '#f4fbff',
      rowSelectedHoverBg: localTheme === ThemeMap.Protect ? '#bee2c2' : '#e4f6ff',
      colorBgContainer: localTheme === ThemeMap.Protect ? '#eefaee' : '#fff',
      borderColor: localTheme === ThemeMap.Protect ? '#929292' : '#f0f0f0',
      headerBg: localTheme === ThemeMap.Protect ? '#e0eae0' : '#fafafa',
    },
    Card: {
      colorBgContainer: localTheme === ThemeMap.Protect ? '#eefaee' : '#fff',
      colorBorderSecondary: localTheme === ThemeMap.Protect ? '#929292' : '#f0f0f0',
    },
    Input: {
      activeBg: localTheme === ThemeMap.Protect ? '#eefaee' : '#fff',
      colorBgContainer: localTheme === ThemeMap.Protect ? '#eefaee' : '#fff',
      colorBorder: localTheme === ThemeMap.Protect ? '#adadad' : '#d9d9d9',
    },
    InputNumber: {
      activeBg: localTheme === ThemeMap.Protect ? '#deebdf' : '#fff',
      colorBgContainer: localTheme === ThemeMap.Protect ? '#deebdf' : '#fff',
      colorBorder: localTheme === ThemeMap.Protect ? '#adadad' : '#d9d9d9',
    },
    Select: {
      selectorBg: localTheme === ThemeMap.Protect ? '#deebdf' : '#fff',
      colorBgElevated: localTheme === ThemeMap.Protect ? '#e7f4e8' : '#fff',
      colorBorder: localTheme === ThemeMap.Protect ? '#adadad' : '#d9d9d9',
    },
    DatePicker: {
      activeBg: localTheme === ThemeMap.Protect ? '#deebdf' : '#fff',
      colorBgElevated: localTheme === ThemeMap.Protect ? '#e7f4e8' : '#fff',
    },
    Tabs: {
      colorBorderSecondary: localTheme === ThemeMap.Protect ? '#adadad' : '#f0f0f0',
    },
  };

  return (
    <ConfigProvider
      locale={zhCN}
      theme={{
        components: themeComponents,
        token: {
          colorPrimary: localTheme === ThemeMap.Protect ? '#00b96b' : '#007FFF',
          colorFillContentHover: localTheme === ThemeMap.Protect ? '#C7EDCC' : '#fff',
          colorFillContent: '#fff',
        },
      }}
    >
      <Router>
        <Layouts />
      </Router>
    </ConfigProvider>
  );
};

const App = () => {
  return (
    <Provider store={store}>
      <ConfigComp />
    </Provider>
  );
};
export default App;
