import { PANGU_AUTH_ID_MAP } from './constant/common';

const wujiDbMap = {
  development: 'db_ug_caidata_test',
  production: 'db_ug_caidata',
};
const env = process.env.NODE_ENV?.toLowerCase();
const dbName = (wujiDbMap as any)[env];
const pathConf = {
  allUser: `https://wuji.woa.com/p/edit?appid=${dbName}&schemaid=t_caidata_users`,
  theme: `https://wuji.woa.com/p/edit?appid=${dbName}&schemaid=t_caidata_bot_theme`,
  markUser: `https://wuji.woa.com/p/edit?appid=${dbName}&schemaid=t_caidata_mark_user`,
  botTypes: `https://wuji.woa.com/p/edit?appid=${dbName}&schemaid=t_caidata_bot_types`,
  bots: `https://wuji.woa.com/p/edit?appid=${dbName}&schemaid=t_caidata_bots`,
  session: `https://wuji.woa.com/p/edit?appid=${dbName}&schemaid=t_caidata_sessions`,
  rejectReason: `https://wuji.woa.com/p/edit?appid=${dbName}&schemaid=t_caidata_reject_reason`,
};

const menusList = [
  {
    name: '首页',
    isQqDomianCheck: true,
    children: [
      {
        name: '数据管理',
        children: [
          {
            name: '整体数据',
            path: '/databoard',
          },
          {
            name: '我的数据',
            path: '/databoard/mydata',
          },
        ],
      },
    ],
  },
  {
    name: '语料生产',
    path: '/corpus_upgraded/project',
    insert: true,
    children: [
      {
        name: '项目管理',
        // isQqDomianCheck: true,
        checkAuth: [PANGU_AUTH_ID_MAP.create_project, PANGU_AUTH_ID_MAP.manage_project],
        children: [
          {
            name: '项目列表',
            path: '/corpus/project_list',
          },
        ],
      },
      {
        name: '任务管理',
        checkAuth: [
          PANGU_AUTH_ID_MAP.mark,
          PANGU_AUTH_ID_MAP.review,
          PANGU_AUTH_ID_MAP.sample,
          PANGU_AUTH_ID_MAP.check,
        ],
        children: [
          {
            name: '生产任务',
            path: '/corpus/my_tasks',
            checkAuth: [PANGU_AUTH_ID_MAP.mark],
          },
          {
            name: '质检任务',
            path: '/corpus/review_tasks',
            checkAuth: [PANGU_AUTH_ID_MAP.review],
          },
          {
            name: '抽样任务',
            path: '/corpus/sampling_tasks',
            isQqDomianCheck: true,
            checkAuth: [PANGU_AUTH_ID_MAP.sample],
          },
          {
            name: '验收任务',
            path: '/corpus/check_tasks',
            isQqDomianCheck: true,
            checkAuth: [PANGU_AUTH_ID_MAP.check],
          },
          {
            name: '已验收任务',
            path: '/corpus/checked_tasks',
            isQqDomianCheck: true,
            checkAuth: [PANGU_AUTH_ID_MAP.check],
          },
          {
            name: '语料列表',
            path: '/corpus/qa_list',
            isQqDomianCheck: true,
            checkAuth: [PANGU_AUTH_ID_MAP.check],
          },
        ],
      },
      {
        name: '评测管理',
        checkAuth: [PANGU_AUTH_ID_MAP.cai_blind_review],
        children: [
          {
            name: '模型盲评',
            path: '/review/cai_blind_review',
            checkAuth: [PANGU_AUTH_ID_MAP.cai_blind_review],
          },
          {
            name: '对比盲评',
            path: '/review/qa_review',
            checkAuth: [PANGU_AUTH_ID_MAP.cai_blind_review],
          },
        ],
      },
      {
        name: '人设管理',
        checkAuth: [PANGU_AUTH_ID_MAP.create_bot],
        isQqDomianCheck: true,
        children: [
          {
            name: '人设列表',
            path: '/bots/index',
          },
        ],
      },
      {
        // 目前权限项放开
        name: '新流程生产项目',
        children: [
          {
            name: '项目列表',
            path: '/corpus_upgraded/project',
            checkAuth: [
              PANGU_AUTH_ID_MAP.create_project,
              PANGU_AUTH_ID_MAP.manage_project,
              PANGU_AUTH_ID_MAP.mark,
              PANGU_AUTH_ID_MAP.review,
              PANGU_AUTH_ID_MAP.sample,
              PANGU_AUTH_ID_MAP.check,
            ],
          },
          {
            name: '任务列表',
            path: '/corpus_upgraded/task',
            checkAuth: [
              PANGU_AUTH_ID_MAP.create_project,
              PANGU_AUTH_ID_MAP.manage_project,
              PANGU_AUTH_ID_MAP.mark,
              PANGU_AUTH_ID_MAP.review,
              PANGU_AUTH_ID_MAP.sample,
              PANGU_AUTH_ID_MAP.check,
            ],
            isShow: false,
          },
          {
            name: 'QA列表',
            path: '/corpus_upgraded/qa',
            checkAuth: [
              PANGU_AUTH_ID_MAP.create_project,
              PANGU_AUTH_ID_MAP.manage_project,
              PANGU_AUTH_ID_MAP.mark,
              PANGU_AUTH_ID_MAP.review,
              PANGU_AUTH_ID_MAP.sample,
              PANGU_AUTH_ID_MAP.check,
            ],
            isShow: false,
          },
          {
            name: '模板管理',
            path: '/corpus_upgraded/template',
            checkAuth: [PANGU_AUTH_ID_MAP.template_manage],
          },
        ],
      },
    ],
  },
  {
    name: '工具管理',
    isQqDomianCheck: true,
    children: [
      {
        name: '语料配置',
        checkAuth: [PANGU_AUTH_ID_MAP.tool_config],
        children: [
          {
            name: '角色配置',
            path: `/config/wuji/${encodeURIComponent(pathConf.allUser)}`,
          },
          // {
          //   name: '标注人配置',
          //   path: `/config/wuji/${encodeURIComponent(pathConf.markUser)}`,
          // },
          {
            name: '主题配置',
            path: `/config/wuji/${encodeURIComponent(pathConf.theme)}`,
          },
          {
            name: 'bot类型配置',
            path: `/config/wuji/${encodeURIComponent(pathConf.botTypes)}`,
          },
          {
            name: 'bot人设配置',
            path: `/config/wuji/${encodeURIComponent(pathConf.bots)}`,
          },
          {
            name: '会话配置',
            path: `/config/wuji/${encodeURIComponent(pathConf.session)}`,
          },
          {
            name: '驳回原因配置',
            path: `/config/wuji/${encodeURIComponent(pathConf.rejectReason)}`,
          },
        ],
      },
      {
        name: '账号体系',
        checkAuth: [PANGU_AUTH_ID_MAP.approve_auth],
        children: [
          {
            name: '权限审批',
            path: '/account/approve',
          },
        ],
      },
    ],
  },
];
export default menusList;
export interface MenuItem {
  name: string;
  path?: string;
  description?: string;
  children?: MenuItem[];
  width?: number;
  disabled?: boolean;
}
