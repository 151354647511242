import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useConcent } from 'concent';

import { LOGIN_TYPE, PANGU_AUTH_ID_MAP } from 'configs/constant/common';
import { getUrlParams } from 'utils/url';

import './wxLogin.less';

type IAuthRoute = {
  loginType: any;
  getCaiUserInfo: any;
  children: any;
  meta: Record<string, any> | null;
};
function WxLogin(props: IAuthRoute) {
  const { getCaiUserInfo = null, loginType, meta = {} } = props;
  const { code } = getUrlParams(window.location.href);
  const userCtx = useConcent({ module: 'user' });
  const { userId, authList } = userCtx.state;
  const [isAuth, setIsAuth] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    initAuth();
  }, []);

  useEffect(() => {
    userId && setIsAuth(true);
  }, [userId]);

  const getExternalLoginConfig = async () => {
    const appInfo = await userCtx?.dispatch('getExternalLoginConfig');
    return appInfo;
  };

  const redirectLogic = async (isNeedWxLogin = false) => {
    getCaiUserInfo?.(isNeedWxLogin);
  };

  const toAuth = async () => {
    const appInfo = await getExternalLoginConfig();
    const backUrl = `${origin}/`;

    const authUrl = `https://open.weixin.qq.com/connect/qrconnect?appid=${
      appInfo?.appId
    }&redirect_uri=${encodeURIComponent(backUrl)}&response_type=code&scope=snsapi_login#wechat_redirect`;
    console.log('authUrl', authUrl, isAuth);
    // window.open(authUrl, '_blank');
    window.location.replace(authUrl);
  };

  const setWxUserInfo = async ({ nickname = '', headimgurl = '' }) => {
    nickname && localStorage.setItem('UG_CAIDATA_NAME', nickname);
    headimgurl &&
      localStorage.setItem(
        'UG_CAIDATA_AVATAR',
        headimgurl || 'https://upage.imtt.qq.com/m_imtt/wx-quiz/images/avatar-back.png',
      );
  };

  const initAuth = async () => {
    const openid = localStorage['UG_CAIDATA_OPENID'];
    if (code && !openid) {
      //   授权之后跳转回来
      const userInfo = await userCtx?.dispatch('getWxUserInfo', { code });
      const { nickname, openid } = userInfo;
      if (openid && openid !== 'undefined') {
        // 设置openid到本地cache
        localStorage.setItem('UG_CAIDATA_OPENID', openid);
        if (nickname && nickname !== 'undefined') {
          // 设置用户数据（本地)
          if (nickname !== '微信用户') {
            await setWxUserInfo({ ...userInfo });
            redirectLogic();
          } else {
            // 等待用户授权
          }
        } else {
          // 刷token，换头像
          const userInfo = await userCtx?.dispatch('getUserInfoByOpenid', { openid });
          if (userInfo) {
            // const { nickname, headimgurl, expire = '' } = userRes.userInfo;
            await setWxUserInfo({ ...userInfo });
            redirectLogic();
          } else {
            // 用刷新token的方式重新获取但失败，需要重新授权获取
            toAuth();
          }
        }
      }
    } else {
      const nickname = localStorage['UG_CAIDATA_NAME'];
      const headimgurl = localStorage['UG_CAIDATA_AVATAR'];
      if (openid && openid !== 'undefined') {
        if (nickname && nickname !== 'undefined') {
          userCtx.setState({ user: nickname, avatar: headimgurl, login: true });
          redirectLogic(true);
        } else {
          // 刷token，换头像
          const userInfo = await userCtx?.dispatch('getUserInfoByOpenid', { openid });
          if (userInfo) {
            // const { nickname, headimgurl, expire } = userRes.userInfo;
            await setWxUserInfo({ ...userInfo });
            redirectLogic();
          } else {
            // 用刷新token的方式重新获取但失败，需要重新授权获取
            toAuth();
          }
        }
      } else {
        // 去授权
        toAuth();
      }
    }
  };

  if (isAuth && !localStorage['UG_CAIDATA_OPENID']) {
    navigate('/login');
    return;
  }
  // 首次扫码进入或权限未请求回不渲染
  if (loginType === LOGIN_TYPE.wx || authList === null) {
    return null;
  }
  // login等白名单页面直接渲染
  if (meta?.white) {
    return props.children;
  }

  // 修改外部默认跳转地址
  if (
    authList?.length > 0 &&
    pathname === '/corpus/my_tasks' &&
    authList.includes(PANGU_AUTH_ID_MAP.cai_blind_review) &&
    !authList.includes(PANGU_AUTH_ID_MAP.mark)
  ) {
    navigate('/review/cai_blind_review');
    return null;
  }

  // 有用户Id查询后如果还没有权限查询返回，则跳转无权限页面
  return userId && !authList?.length ? <Navigate to='/exception-403' replace={true}></Navigate> : props.children;
}

export default WxLogin;
