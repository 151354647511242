export const DATEFORMAT = 'YYYY-MM-DD';
export const DATEFORMAT_WHOLE = 'YYYY-MM-DD HH:mm:ss';
export const DATEFORMAT_MINUTE = 'YYYY-MM-DD HH:mm';

// 流程类型
export const PROCESS_TYPE = {
  PRODUCE: {
    value: 1,
    text: '生产',
  },
  REVIEW: {
    value: 2,
    text: '质检',
  },
  CHECK: {
    value: 3,
    text: '验收',
  },
  RECEIPT: {
    value: 4,
    text: '入库',
  },
  ANNOTATION: {
    value: 5,
    text: '标注',
  },
};

// 自定义字段类型
export const CUSTOMIZE_FIELD_TYPE = {
  TEXT: {
    value: 1,
    text: '文本',
  },
  NUMBER: {
    value: 2,
    text: '数字',
  },
  SELECT: {
    value: 3,
    text: '选项',
  },
  USER: {
    value: 4,
    text: '人员',
  },
  TIME: {
    value: 5,
    text: '时间',
  },
};

// 选项类型
export const FILTER_TYPE = {
  SINGLE: {
    value: 1,
    text: '单选',
  },
  MULTIPLE: {
    value: 2,
    text: '多选',
  },
};

// 状态
export const STATUS_TYPE = {
  OFF: {
    value: 0,
    text: '禁用',
  },
  ON: {
    value: 1,
    text: '启用',
  },
};

// 任务类型 - 新流程
export const TASK_STATUS_UPGRADED = {
  PRODUCE: {
    value: 1,
    text: '未入库',
  },
  RECEIPT: {
    value: 4,
    text: '入库',
  },
};

// 主题颜色
export enum ThemeMap {
  Light = 'light', // 普通模式
  Protect = 'protect', // 护眼模式
}
