import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import type { MenuTheme } from 'antd';
import { getIsQqDomain } from 'utils/common';
const { Item, SubMenu } = Menu;

// 菜单项目
const menuItems = (menus: any[], { authList }: any) => menus.map((item: {
  name: any;
  path: any;
  children: any;
  isOuterLink: any;
  isTab: any;
  link: any;
  target: string;
  checkAuth: any;
  isQqDomianCheck: boolean;
  isShow: boolean;
}) => {
  const { name, path, children, target = '', checkAuth = [], isQqDomianCheck = false, isShow = true } = item;
  const title = (
        <span>
          <span>{name}</span>
        </span>
  );
  // 权限限制用户不展示风险菜单
  const isCheckAuthPass =        checkAuth?.length > 0
    ? checkAuth?.findIndex((auth: any) => (auth === 0 || auth) && authList?.length > 0 && authList?.includes(auth)) > -1
    : true;
  const isNoCheckDomain = isQqDomianCheck && getIsQqDomain();
  const isNoAuth = (authList?.length > 0 && !isCheckAuthPass) || isNoCheckDomain;

  if (isNoAuth || !isShow) {
    return null;
  }
  if (children && !path) {
    return (
          <SubMenu key={name} title={title}>
            {menuItems(children, { authList })}
          </SubMenu>
    );
  }
  return (
        <Item key={path}>
          <Link to={path} target={target}>
            {title}
          </Link>
        </Item>
  );
});

const SideMenu = ({
  userInfo = {},
  pathname = '',
  openKeys: openKeysArr = [] as any,
  menus = [] as any,
  theme = 'light' as MenuTheme,
}) => {
  const [openKeys, setOpenKeys] = useState(openKeysArr || []);
  useEffect(() => {
    setOpenKeys(openKeysArr);
  }, [openKeysArr]);
  return (
    <Menu
      inlineCollapsed={false}
      mode='inline'
      theme={theme}
      style={{ width: '100%' }}
      selectedKeys={[pathname]}
      openKeys={openKeys}
      onOpenChange={openKeys => setOpenKeys(openKeys)}
    >
      {menuItems(menus, { ...userInfo })}
    </Menu>
  );
};

export default SideMenu;
