import { message } from 'antd';
import axios from 'axios';
import { USER_STATUS_MAP } from 'configs/constant/common';
import { getLoginAccountType } from 'utils/common';

axios.defaults.timeout = 100000;
axios.defaults.withCredentials = true;

type IAnyObj = Record<string, any>;

const checkCode = ({ code, msg = '接口格式错误', data = true }: IAnyObj) => {
  if (code !== 0) {
    // 鉴权返回错误统一处理
    if ([USER_STATUS_MAP.isExpired].includes(data.userStatus)) {
      localStorage.removeItem('UG_CAIDATA_OPENID');
      localStorage.removeItem('UG_CAIDATA_NAME');
      localStorage.removeItem('UG_CAIDATA_AVATAR');
      localStorage.removeItem('UG_CAIDATA_LOGIN_TYPE');
      window.location.href = `${window.location.origin}/#/login`;
      return;
    } else if (
      [
        USER_STATUS_MAP.noUser,
        USER_STATUS_MAP.noAuthInPangu,
        USER_STATUS_MAP.rejected,
        USER_STATUS_MAP.unApprovedInRedis,
      ].includes(data.userStatus)
    ) {
      window.location.href = `${window.location.origin}/#/exception-403`;
      // 保存请求的无权限类型，用于前端差异化提示
      localStorage.setItem('UG_CAIDATA_NO_AUTH_TYPE', data.userStatus);
      return;
    } else if ([USER_STATUS_MAP.error].includes(data.userStatus)) {
      throw new Error('账户信息查询异常，请稍后重试');
    }
    throw new Error(msg);
  }
  return data;
};

const http = async ({ checkFn = checkCode, defaultValue, ...rest }: IAnyObj) => {
  const options = { ...rest };
  try {
    // get 请求通过params发送参数
    if (options.method === 'get' || !options.method) {
      options.params = options.data;
      delete options.data;
    }
    // 评测后台接口header字段
    // const regex = /^backend\/cai\/*/;
    // const isEvaiUrlresult = regex.test(options.url);
    // if (isEvaiUrlresult) {
    //   options.headers = {
    //     ...options.headers,
    //     project: options.params?.project,
    //     user: options.params?.user,
    //   };
    //   delete options.params?.project;
    //   delete options.params?.user;
    // }
    options.headers = Object.assign({}, options.headers || {}, {
      'X-Requested-With': 'XMLHttpRequest',
      // 'user-id': getUserKey() || (window as any).USER_ID, // 微信的外部用户传递用户Id过去
      'account-type': getLoginAccountType(), // 避免上次psw登录的userId会干扰rtx的userId
    });
    const { data } = await axios(options);
    // 临时兼容开发阶段后台返回mock数据的调试需要，后期上线可删
    if (data?.code === undefined) {
      return data;
    }
    return checkFn(data);
  } catch (error) {
    if (error?.response?.status === 401) {
      // ajax请求无法自动处理302实现重定向，需要加X-Requested-With，让智能网关返回401，手动跳转，详见下文
      // http://km.oa.com/group/35877/articles/show/370833?kmref=search&from_page=1&no=3
      return window.location.reload();
    }
    message.error(error.message);
    // 返回默认值
    return defaultValue;
  }
};

export const get = async (url: string, params: IAnyObj, rest?: IAnyObj) =>
  await http({
    method: 'get',
    url,
    data: params,
    ...rest,
  });

export const post = async (url: string, params: IAnyObj, rest?: IAnyObj) =>
  await http({
    method: 'post',
    url,
    data: params,
    ...rest,
  });
