import { Suspense, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Button, Layout, Spin } from 'antd';
import { useConcent } from 'concent';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

import ROUTER_CONFIG from 'configs/routes';
import Headers from 'components/Headers';
import SideMenu from 'components/SideMenu';
import menus from 'configs/menus';
import { getIsWxLogin, getIsExistLoginType, getLoginAccountType } from 'utils/common';
import { getUrlParams } from 'utils/url';
import { LOGIN_TYPE, ReportActId, ReportActStatus } from 'configs/constant/common';
import { reportAct } from 'models/external/reducer';

import AuthRoute from './authRoute';
import { getPathQueue } from './helper';
import WxLogin from './wxLogin';

import 'assets/css/index.less';
import './index.less';

const { Content, Sider } = Layout;

function Layouts() {
  const navigate = useNavigate();
  const userCtx = useConcent({ module: 'user' });
  const { userId, authList } = userCtx.state;
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const { pathname } = location;
  const { loginType = '' as any } = getUrlParams(window.location.href);
  if (loginType === LOGIN_TYPE.rtx) {
    localStorage.setItem('UG_CAIDATA_LOGIN_TYPE', LOGIN_TYPE.rtx);
  }
  // // 菜单相关计算属性
  const paths = getPathQueue(menus, pathname); // 面包屑导航路径
  const openKeys = paths.map((m) => m.name); // 打开的菜单
  const handMenuName = paths?.[0]?.name; // 头部选中菜单
  const sideMenu: any = menus.find((i) => i.name === handMenuName) || {}; // 头部菜单
  // 是否微信登录
  const isWxLogin = getIsWxLogin(localStorage.getItem('UG_CAIDATA_LOGIN_TYPE') || '');

  const getCaiUserInfo = async () => {
    const loginLocalType = getLoginAccountType();
    const isRtxLogin = [LOGIN_TYPE.rtx].includes(loginLocalType);
    if (isRtxLogin) {
      await userCtx?.dispatch('/woaLogin');
    }

    // 权限页面展示头像信息但不需要再请求用户信息
    if (['/exception-403']?.includes(pathname)) {
      return;
    }

    const data = await userCtx?.dispatch('getCaiDataUserInfo', {
      account_type: getLoginAccountType(),
    });

    if (data) {
      // 登录·成功上报
      reportAct({
        subpos: ReportActId.CAIDATA_SYS_DATA,
        acttype: 3, // 曝光是2，点击是3
        extra: {
          userId: data?.user_id,
          status: ReportActStatus.LOGIN,
          qaLength: 0,
        },
      });
      return;
    }
  };

  useEffect(() => {
    (window as any).USER_ID = userId;
    // 根据缓存的登录类型判断是否有登录态
    if (!getIsExistLoginType()) {
      navigate('/login');
      return;
    }
    if (!['/login']?.includes(pathname) && !userId && !isWxLogin) {
      // 其他登录方式获取用户信息
      getCaiUserInfo();
    }
  }, [userId]);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout style={{ height: '100vh' }} className='layout-container'>
      <Headers menus={menus} pathname={pathname} selectedKey={handMenuName}></Headers>
      <Layout className='ant-layout-container'>
        {openKeys.length > 0 ? (
          <Sider
            theme='light'
            trigger={null}
            collapsible
            collapsed={collapsed}
            collapsedWidth={0}
            className='caidata-sider'
          >
            <SideMenu
              menus={sideMenu.children}
              openKeys={openKeys}
              pathname={pathname}
              userInfo={{ authList }}
            ></SideMenu>
            <Button type='primary' className='collapse-button' onClick={toggleCollapse}>
              {collapsed ? <RightOutlined /> : <LeftOutlined />}
            </Button>
          </Sider>
        ) : null}
        <Content className='ml10'>
          <Suspense fallback={<Spin tip='Loading' size='large' className='content-spin'></Spin>}>
            <Routes>
              {ROUTER_CONFIG?.map((props, index) => (
                <Route
                  path={props.path}
                  key={index}
                  // 外部wx体系登录用户
                  element={
                    isWxLogin ? (
                      <WxLogin getCaiUserInfo={getCaiUserInfo} meta={props.meta || null} loginType={loginType}>
                        {props.element}
                      </WxLogin>
                    ) : (
                      <AuthRoute meta={props.meta || null}>{props.element}</AuthRoute>
                    )
                  }
                />
              ))}
            </Routes>
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Layouts;
