import { Layout, Tooltip, Switch } from 'antd';
import { useConcent } from 'concent';
import { connect } from 'react-redux';
import { EyeOutlined, SunOutlined } from '@ant-design/icons';

import UserInfo from 'components/UserInfo';
import HeadMenu from 'components/HeadMenu';
import ButtonLink from 'components/ButtonLink';
import { CURRENT_MAINTAINER } from 'configs/constant/common';
import { getIsQqDomain, getIsRtxLogin } from 'utils/common';

import './index.less';

const { Header } = Layout;

const userItemGap = 24;
interface RootState {
  localTheme: string;
}
interface RootDispatch {
  setReduxData: Function;
}
type Props = {
  menus: any;
  selectedKey: string;
  pathname: string;
} & RootState &
  RootDispatch;

const mapState = (state: { layouts: RootState }) => {
  const { localTheme } = state.layouts;
  return { localTheme };
};

const mapDispatch = (dispatch: { layouts: RootDispatch }) => {
  const { setReduxData } = dispatch.layouts;
  return {
    setReduxData,
  };
};

function Headers(props: Props) {
  const userCtx = useConcent({ module: 'user' });
  const { menus, selectedKey } = props;
  const { userId, accountType, authList } = userCtx.state;
  const onLogoClick = () => {
    if (!userId) {
      return;
    }
    window.location.href = `${window.location.origin}/`;
  };

  const isRtxLogin = getIsRtxLogin(accountType);

  const toggleTheme = () => {
    const localTheme = localStorage.getItem('localTheme');
    const newTheme = localTheme === 'protect' ? 'light' : 'protect';
    localStorage.setItem('localTheme', newTheme);
    props.setReduxData({ key: 'localTheme', data: newTheme });
  };

  return (
    <Header className='head-box'>
      <div className='logo-cont' onClick={onLogoClick}>
        {/* <img alt="logo" src="https://kandian-1258344701.file.myqcloud.com/caidata_admin/logo_v2.jpg" className={styles.logo_img} /> */}
        <h3 className='title'>语料生产系统</h3>
      </div>

      {authList?.length > 0 && userId ? (
        <HeadMenu menus={menus} selectedKey={selectedKey} authList={authList} userId={userId}></HeadMenu>
      ) : null}
      {/* 暂时隐藏，后续后台能力ready再展示出来即可 */}
      <div className='user-box'>
        <Tooltip title={localStorage.getItem('localTheme') === 'protect' ? '关闭护眼模式' : '开启护眼模式'}>
          <Switch
            checkedChildren={<EyeOutlined />}
            unCheckedChildren={<SunOutlined />}
            onChange={toggleTheme}
            defaultValue={localStorage.getItem('localTheme') === 'protect'}
          />
        </Tooltip>
        {authList?.length > 0 ? (
          <>
            {userId && !getIsQqDomain() ? (
              <ButtonLink
                isHttpUrl={true}
                style={{ paddingLeft: userItemGap, paddingRight: userItemGap }}
                antdOptions={{
                  href: 'https://doc.weixin.qq.com/doc/w3_AbkAigYpAAYMpD3UvnOQiWpmjXBOq?scode=AJEAIQdfAAoyZ1IqNwAbkAigYpAAY',
                  target: '_blank',
                }}
              >
                使用文档
              </ButtonLink>
            ) : null}

            {userId && isRtxLogin && !getIsQqDomain() ? (
              <span className='desc-text'>
                <Tooltip mouseEnterDelay={0} className='feedback' title={`请企业微信联系 ${CURRENT_MAINTAINER}`}>
                  <a href={`wxwork://message?username=${CURRENT_MAINTAINER}`}>意见反馈</a>
                </Tooltip>
              </span>
            ) : null}
          </>
        ) : null}
        {/* <span className={styles.desc_text}>权限</span> */}

        <UserInfo></UserInfo>
      </div>
    </Header>
  );
}

export default connect(mapState, mapDispatch)(Headers);
