import { createModel } from '@rematch/core';
import { ThemeMap } from 'constants/common';

export default createModel()({
  state: {
    localTheme: localStorage.getItem('localTheme') || ThemeMap.Light,
  },
  reducers: {
    setState: (state: any, payload: any) => ({ ...state, ...payload }),
  },
  effects: {
    setReduxData({ key, data }) {
      this.setState({
        [key]: data,
      });
    },
  },
});
